.card-component {
  background-color: #152a44;
  padding: 20px 15px 0px 15px;
  margin-bottom: 20px;
  border-radius: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-info {
  color: #f3f3f3;
  margin-bottom: 10px;
}

.card-name {
  font-size: 22px;
  font-weight: bold;
  font-family: "Mansalva", cursive;
}

.card-amount {
  font-size: 22px;
  margin-bottom: 10px;
}

.card-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.card-icon {
  width: 24px;
  height: 24px;
  margin: 0 5px;
  cursor: pointer;
}
.card-icon:hover {
  transform: scale(1.1);
}

.card-image {
  max-width: 100%;
  height: auto;
}
