.backdrops {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111111;
}

.modalss {
  max-width: 750px;
  width: 100%;
  background-color: #001125;
  border-radius: 0px;
  padding: 30px 20px;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalss .close-buttons {
  background: none;
  border: none;
  cursor: pointer;
}

.modalss .close-buttons:hover {
  transform: scale(1.1);
}
.content {
  margin-top: 20px;
  text-align: center;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.footer a,
.footer button {
  flex: 1;
}
.game-mechanicss {
  font-family: "Arial", sans-serif;
  color: #fff;
  line-height: 1.6;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  height: 680px;
  overflow: hidden;
}

.title {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff;
}

.mechanic {
  margin-bottom: 20px;
}

.mechanic h2 {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 10px;
}

.mechanic ul {
  list-style-type: disc;
  padding-left: 20px;
}

.mechanic ul li {
  margin-bottom: 10px;
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .modals {
    max-width: 90%;
  }
  .close-button {
    top: -50px;
    right: 0px;
  }
}
